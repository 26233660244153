// src/components/EventManagement.js
import React, { useEffect, useState } from 'react';

function EventManagement() {
  const [events, setEvents] = useState([]);
  const apiUrl = 'https://api.eventsip.staedler.net/';
  let jwtToken = localStorage.getItem('jwtToken');

  useEffect(() => {
    fetch(apiUrl + 'events', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Fehler beim Abrufen der Events');
      }
      return response.json();
    })
    .then(data => setEvents(data))
    .catch(error => console.error('Fehler:', error));
  }, [jwtToken]);

  return (
    <div>
      <h2>Event-Management</h2>
      <ul>
        {events.map(event => (
          <li key={event.event_id}>{event.name}</li>
        ))}
      </ul>
    </div>
  );
}

export default EventManagement;
