// src/components/EmployeeManagement.js
import React, { useState } from 'react';

function EmployeeManagement() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [userId, setUserId] = useState('');
  const [newTeam, setNewTeam] = useState('Service');

  const apiUrl = 'https://api.eventsip.staedler.net/';
  let jwtToken = localStorage.getItem('jwtToken');

  const registerUser = () => {
    fetch(apiUrl + 'register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
    .then(response => response.json())
    .then(result => {
      if (result.message) {
        alert(result.message);
      } else {
        alert(result.error);
      }
    })
    .catch(error => console.error('Fehler bei der Registrierung:', error));
  };

  const loginUser = () => {
    fetch(apiUrl + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
    .then(response => response.json())
    .then(result => {
      if (result.token) {
        // Speichere den JWT-Token im Local Storage
        localStorage.setItem('jwtToken', result.token);
        alert('Login erfolgreich');
      } else {
        alert(result.error);
      }
    })
    .catch(error => console.error('Fehler bei der Anmeldung:', error));
  };

  const changeTeam = () => {
    fetch(apiUrl + 'change_team', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
      },
      body: JSON.stringify({ user_id: userId, new_team: newTeam })
    })
    .then(response => response.json())
    .then(result => {
      if (result.message) {
        alert(result.message);
      } else {
        alert(result.error);
      }
    })
    .catch(error => console.error('Fehler beim Teamwechsel:', error));
  };

  return (
    <div>
      <h2>Mitarbeiter-Verwaltung</h2>
      <div>
        <h3>Mitarbeiter registrieren</h3>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Benutzername"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Passwort"
        />
        <button onClick={registerUser}>Registrieren</button>
      </div>
      <div>
        <h3>Login</h3>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Benutzername"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Passwort"
        />
        <button onClick={loginUser}>Login</button>
      </div>
      <div>
        <h3>Team wechseln</h3>
        <input
          type="text"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          placeholder="Benutzer-ID"
        />
        <select value={newTeam} onChange={(e) => setNewTeam(e.target.value)}>
          <option value="Service">Service</option>
          <option value="K�che">K�che</option>
          <option value="Bar">Bar</option>
        </select>
        <button onClick={changeTeam}>Team wechseln</button>
      </div>
    </div>
  );
}

export default EmployeeManagement;
