// src/components/MenuManagement.js
import React, { useState } from 'react';

function MenuManagement() {
  const [categoryName, setCategoryName] = useState('');
  const [menuName, setMenuName] = useState('');
  const [menuPrice, setMenuPrice] = useState('');

  const apiUrl = 'https://api.eventsip.staedler.net/';
  let jwtToken = localStorage.getItem('jwtToken');

  const createCategory = () => {
    alert(`Kategorie '${categoryName}' wurde erstellt.`);
  };

  const createMenu = () => {
    alert(`Menü '${menuName}' mit Preis ${menuPrice} wurde erstellt.`);
  };

  return (
    <div>
      <h2>Menü-Verwaltung</h2>
      <div>
        <h3>Menükategorie erstellen</h3>
        <input
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder="Kategorie-Name"
        />
        <button onClick={createCategory}>Kategorie erstellen</button>
      </div>
      <div>
        <h3>Menüelement erstellen</h3>
        <input
          type="text"
          value={menuName}
          onChange={(e) => setMenuName(e.target.value)}
          placeholder="Menü-Name"
        />
        <input
          type="number"
          value={menuPrice}
          onChange={(e) => setMenuPrice(e.target.value)}
          placeholder="Preis"
        />
        <button onClick={createMenu}>Menüelement erstellen</button>
      </div>
    </div>
  );
}

export default MenuManagement;
