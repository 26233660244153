// src/App.js
import React, { useState } from 'react';
import EventManagement from './components/EventManagement';
import EmployeeManagement from './components/EmployeeManagement';
import MenuManagement from './components/MenuManagement';

function App() {
  const [currentTab, setCurrentTab] = useState('events');

  const renderTab = () => {
    switch (currentTab) {
      case 'events':
        return <EventManagement />;
      case 'employees':
        return <EmployeeManagement />;
      case 'menus':
        return <MenuManagement />;
      default:
        return <EventManagement />;
    }
  };

  return (
    <div className="App">
      <nav>
        <button onClick={() => setCurrentTab('events')}>Event-Verwaltung</button>
        <button onClick={() => setCurrentTab('employees')}>Mitarbeiter-Verwaltung</button>
        <button onClick={() => setCurrentTab('menus')}>Menü-Verwaltung</button>
      </nav>
      <main>
        {renderTab()}
      </main>
    </div>
  );
}

export default App;
